<template>
  <div>
    <page-breadcrumb title="Lesson Detail" class="mb-3" :items="breadcrumbItems" />
    <form-generator v-if="item" :model="item" :schema="item_form_schema" />
    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteItem"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import service from '../service'
const item_form_schema = [
  {
    cols: 4,
    fields: [
      { label: 'Thumbnail', field: 'thumbnail', input_type: 'ui-component' },
    ]
  },
  {
    cols: 8,
    fields: [
      { label: 'Lesson Title', field: 'title', validate: { required: true } },
      { label: 'Native 1-1 - Lesson Materials', field: 'material' },
      { label: 'Published?', field: 'is_published', input_type: 'switch'}
    ]
  }
]
export default {
  data() {
    return {
      item: null,
      item_form_schema,
      isUpdating: false,
      isDeleting: false,
    }
  },
  computed: {
    itemId(){
      return this.$route.params.id
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Course',
          to: { name: 'courses-detail', params: {id: this.$route.params.courseId} },
        },
        {
          text: 'Lessons',
          to: { name: "course-lesson", params: {courseId: this.$route.params.courseId} },
        },
      ]
      return items
    },
  },
  created(){
    this.getDetail();
  },
  methods: {
    async getDetail(){
      this.item = await service.get({id: this.itemId});
    },
    async update() {
      this.isUpdating = true;
      let data = {...this.item};
      await service.update({
        data: JSON.stringify(data),
      });
      this.getDetail();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "course-lesson", params: {courseId: this.$route.params.courseId} });
    },
  }
}
</script>